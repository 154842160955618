import { Datatable } from './Datatable';
import { initFourWheelsClusterOptionsTable } from './js/portal/fourwheels';

const tableId = 'table-4wheels-cluster-options';

export default function Admin4WheelsClusterPage() {
  const page = (
    <>
      <div id="manage4WheelsClusterOptionsInfo" class="row">
        <div class="col-lg-12">
          <div class="card form-card">
            <div class="card-header">Cluster Options Configuration</div>
            <div class="card-body">
              <div class="tab-content">
                <div class="tab">
                  <div class="infobox card card-body">
                    <p>
                      On this page you can create, list, update or delete 4Wheels cluster options used to populate the
                      dropdown options on the{' '}
                      <strong>
                        <a href="#create4wheels">create cluster order page</a>
                      </strong>
                      .
                    </p>
                    <p>At the moment, the available options that can be configured are:</p>
                    <ul>
                      <li>CPU Architecture</li>
                      <li>4Wheels Version</li>
                    </ul>
                    <p>
                      <strong>
                        NOTE: There must be at least 1 option per each option type available as the dropdown on the
                        cluster order form will require at least 1 option available.
                      </strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <Datatable tableId={tableId} />
      </div>
    </>
  );

  async function onPageReady() {
    initFourWheelsClusterOptionsTable(tableId);
  }

  return [page, onPageReady];
}
