import { CONF } from '../../../js/portal/environment';
import { COGNITO } from '../../../js/portal/auth';
import {
  checkValueAgainstList,
  createPortalOrder,
  InfoTable,
  showErrorFromApiOperation,
  showTemporaryRoleCommentModal,
  copyButtonJSXHelper,
  copyURLJSXHelper,
  loadOrderJSXHelper,
  hasAccess,
  createSwitchRoleLink,
  createSwitchIdcRoleLink,
  displaySuccessPopup,
  displayErrorPopup,
  addToast,
  showKillSwitchModal,
} from '../../../js/portal/main';
import { baseApiAxios } from '../../../js/portal/api';
import { addSpinner, addButtonSpinner, removeSpinners } from '../../../js/portal/sidebar';
import { checkResponsibility } from '../../../js/portal/dashboard';
import { preEditAccount } from '../../../js/portal/accounts';
import { renderScpDetails } from '../../../js/portal/organizations';
import { enableFeatureFlagControlledElements } from '../../../js/portal/feature_flags';

/**
 * Trigger an update of a given AWS account and add the loading animation to the sidebar and trigger button
 *
 * @param {string} buttonId the id of the button to show the loading animation
 * @param {string} accountId the target AWS account id which should be updated
 */
async function accountUpdateHandler(buttonId, accountId) {
  addSpinner();
  addButtonSpinner(buttonId);

  baseApiAxios
    .triggerAccountUpdate(accountId)
    .then(response => {
      displaySuccessPopup(response.message, 'Your request was successful.');
    })
    .catch(err => {
      showErrorFromApiOperation('Error trigger-update on account' + accountId)(err);
    })
    .finally(removeSpinners);
}

/**
 * Collect all currently assigned SCPs for a given AWS account and show the loading animation of the load button
 *
 * @param {string} buttonId the id of the button to show the loading animation
 * @param {string} accountId the target AWS account id for which we want to collect all SCPs
 * @param {object} sortedOrgUnits a sorted list of OUs from root up to the level where the account is located
 */
async function listScpsHandler(buttonId, accountId, sortedOrgUnits) {
  addSpinner();
  addButtonSpinner(buttonId);

  baseApiAxios
    .getRestrictions(accountId)
    .then(scpDetails => {
      renderScpDetails(scpDetails, accountId, sortedOrgUnits);
    })
    .catch(error => {
      showErrorFromApiOperation('Failed to load SCPs')(error);
    })
    .finally(removeSpinners);
}

export function hasAdminAccountAccess(data) {
  let permissions = localStorage.permissions ? localStorage.permissions : false;
  let account_areas = localStorage.account_areas ? localStorage.account_areas : false;
  let responsible = checkResponsibility(data, COGNITO.user.email);
  let area_admin = checkValueAgainstList(data['account_area'], account_areas);

  return (
    checkValueAgainstList('manage_organizations', permissions) ||
    checkValueAgainstList('manage_accounts', permissions) ||
    responsible ||
    area_admin
  );
}

function hasManageQuarantinePermission() {
  let permissions = localStorage.permissions ? localStorage.permissions : false;

  return checkValueAgainstList('manage_quarantine', permissions);
}

/**
 * Sort the given array of OUs by their parents. The root OU will be on the top, the target account OU on the bottom
 *
 * @param {string} orgOuId the current OU id of the target AWS account
 * @param {object} orgUnits an array with all OU ids which are parents of the orgOuId (one OU can have one parent OU)
 * @returns
 */
function sortParents(orgOuId, orgUnits) {
  // Create a new object out of the list of org units. The map will look like this:
  // {
  //   'r-z4vv': { org_out_id: 'r-z4vv', org_ou_name: 'Root' },
  //   'ou-z4vv-4idk2bbb': {
  //     org_out_id: 'ou-z4vv-4idk2bbb',
  //     org_ou_name: 'FPC-Administration',
  //     org_ou_parent_id: 'r-z4vv',
  //   },
  //   'ou-z4vv-kb5t5rc9': {
  //     org_out_id: 'ou-z4vv-kb5t5rc9',
  //     org_ou_name: 'Operations',
  //     org_ou_parent_id: 'ou-z4vv-4idk2bbb',
  //   },
  // }
  const temp = orgUnits.reduce((ouMap, ou) => {
    ouMap[ou.org_ou_id] = ou;
    return ouMap;
  }, {});
  let result = [];

  // Now we can scan through the new temporary object from above via while loop and always take the org_ou_parent_id
  // to go one level higher from the given OrgOuId up to the root OU in the tree
  let currentOu = orgOuId;
  while (temp[currentOu]) {
    result.push(temp[currentOu]);
    currentOu = temp[currentOu]?.org_ou_parent_id;
  }

  // Return the results in the reverse order, as we want to show the root OU on the top and the target OU on the bottom
  return result.reverse();
}

/**
 * Render the OU structure to visualize the hierarchy of the AWS account and its parent OUs
 *
 * @param {object} orgUnits an array with all OU ids which are parents of the orgOuId (one OU can have one parent OU)
 * @param {object} data the actual account item from the `account-information` table
 * @returns
 */
function renderTreeView(orgUnits, data) {
  // Create the outer container which contains the rendered OU structure
  const wrapper = document.createElement('div');
  wrapper.classList = 'pt-3';

  // Add all parent OUs of the account to the tree
  let currentOu = wrapper;
  orgUnits.forEach(ou => {
    const ouText = `${ou.org_ou_name} (${ou.org_ou_id})`;
    const li = (
      <li>
        <span class="fa-li">
          <i class="fa fa-angle-right"></i>
        </span>
        {ouText}
        <span class="float-end">{ou.org_scps.length || '0'} SCPs assigned</span>
      </li>
    );
    const ul = <ul class="fa-ul">{li}</ul>;
    currentOu.appendChild(ul);
    currentOu = li;
  });

  // Add the actual AWS account itself to the tree
  const friendlyName = data.account_friendly_name ?? '';
  const newFriendlyName = friendlyName.length > 33 ? friendlyName.substring(0, 30).trim() + '...' : friendlyName;
  const ouText = `${newFriendlyName} (${data.account_id})`;
  const li = (
    <li>
      <span class="fa-li">
        <i class="fa fa-angle-right"></i>
      </span>
      <strong class="text-truncate">{ouText}</strong>
      <span class="float-end">{data.org_scps?.length || '0'} SCPs assigned</span>
    </li>
  );
  const ul = <ul class="fa-ul">{li}</ul>;
  currentOu.appendChild(ul);

  // Return the fully rendered object
  return wrapper;
}

/**
 * This is the main content which represents the whole `AWS Account Details` content incl. all of the tabs.
 *
 * @param {object} data
 * @param {Array} tempAccessRoles
 * @param {boolean} isAccountDetailsPage
 * @returns {JSX.Element} the rendered account details incl. the tab content
 */
export default function AccountTabs({ data, tempAccessRoles, isAccountDetailsPage }) {
  // TODO: this is the only thing that can't controlled by async feature flag
  // don't use this code as example
  // use enableFeatureFlagControlledElements instead
  const featureFlagIdc = JSON.parse(sessionStorage.getItem('FeatureFlag.idc'));

  // use follow code as feature flag example
  // enable feature flag controlled elements
  enableFeatureFlagControlledElements('region.enable_regions', 'feature-flag-region-enable-regions');
  enableFeatureFlagControlledElements('region.disable_regions', 'feature-flag-region-disable-regions');
  enableFeatureFlagControlledElements('idc.enabled', 'feature-flag-idc-enabled');
  enableFeatureFlagControlledElements('temporary-privilege-escalation.enabled', 'temporary-privilege-escalation');

  const showTempRoleModal = (button, role) => {
    showTemporaryRoleCommentModal(button, data, role, false);
  };

  // account details
  const editAccountFct = isAccountDetailsPage
    ? () => {
        preEditAccount(data);
      }
    : () => {
        window.location.href = `?account_id=${data.account_id}#accountdetails`;
      };

  const enableRegion = () => {
    window.location.href = `/region/enable-region?account_id=${data.account_id}`;
  };
  const disableRegion = () => {
    window.location.href = `/region/disable-region?account_id=${data.account_id}`;
  };

  const unquarantineAccount = async accountId => {
    // TODO: Do we need to add a reason and incident id for unquarantine since it is not used in the backend and
    // already exists in DB?
    const payload = {
      account_id: accountId,
      action: 'unquarantine-account',
      incident_id: 'placeholder incident id',
    };

    const requestBody = {
      action: 'create-order',
      payload,
    };
    addButtonSpinner('unquarantine-account-button');
    let unquarantineButton = document.getElementById('unquarantine-account-button');
    unquarantineButton.disabled = true;
    unquarantineButton.innerText = 'Submitting reactivation order...';
    const res = await baseApiAxios.post('/orders', requestBody);
    unquarantineButton.innerText = 'Reactivation order submitted.';
    //removeSpinners();
  };
  let order_rows = [];
  if (data.orders) {
    order_rows = Object.getOwnPropertyNames(data.orders).map(order_id => (
      <tr class="compressed" role="row">
        <td>
          {order_id}
          {order_id && (
            <>
              {copyButtonJSXHelper()}
              {loadOrderJSXHelper()}
            </>
          )}
        </td>
        <td>
          {data.orders[order_id].action}
          {data.orders[order_id].action && copyButtonJSXHelper()}
        </td>
        <td>
          {data.orders[order_id].proposer}
          {data.orders[order_id].proposer && copyButtonJSXHelper()}
        </td>
        <td>
          {data.orders[order_id].creation_date}
          {data.orders[order_id].creation_date && copyButtonJSXHelper()}
        </td>
      </tr>
    ));
  }

  // alternate contacts
  const alternateContactsEnabled = !!data.alternate_contacts;

  const isAccountQuarantined = data.account_area === 'quarantine';
  const deletedAccount = data.fpc_status === 'deleted';
  const isAdministrationAccount = data.account_area === 'fpc-administration';

  if (
    data.alternate_contacts?.OPERATIONS?.EmailAddress?.split?.('@')?.[1] === 'list.bmw.com' &&
    hasAdminAccountAccess(data)
  ) {
    data.alternate_contacts.OPERATIONS.actions = 'View/Edit Distribution List Members';
    data.alternate_contacts.OPERATIONS.account_id = data.account_id;
    data.alternate_contacts.OPERATIONS.dl_type = 'operations_dl';
  }

  // cost data
  let costDataContent;
  let costDataStatus = data?.cost_data?.cost_data_status || data?.cost_data_status;
  if (data.cost_data && costDataStatus && (costDataStatus === 'VALID' || costDataStatus === 'APPROVED')) {
    data.cost_data['default'] = data['cost_data_default'];
    costDataContent = (
      <InfoTable
        data={data.cost_data}
        rowInfos={[
          { Name: 'APP ID', Value: 'app_id' },
          { Name: 'APPD ID', Value: 'appd_id' },
          { Name: 'APPD NAME', Value: 'appd_name' },
          { Name: 'CMDB ID', Value: 'cmdb_id' },
          { Name: 'Cost Center', Value: 'cost_center' },
          { Name: 'PSO/PSP', Value: 'psp' },
          { Name: 'IT Responsible', Value: 'it_responsible' },
          { Name: 'Status', Value: 'cost_data_status' },
        ]}
      />
    );
  } else {
    costDataContent = (
      <strong>
        Cost data is not validated for this account or data is not valid. Please visit the Billing DB Portal.
        <p>
          The current status is:{' '}
          {data.cost_data && data.cost_data['cost_data_status'] ? data.cost_data['cost_data_status'] : 'INVALID'}
        </p>
      </strong>
    );
  }

  let sbusDataContent;
  if (hasAdminAccountAccess(data)) {
    if (
      data.sbus_data &&
      data.sbus_data.cloudroom &&
      data.sbus_data.cloudroom.CoMModel &&
      data.sbus_data.cloudroom.CoMModel.CloudRoom
    ) {
      const status_code = data.sbus_data['application_relation']?.['statuscode'];
      if (status_code === 200) {
        sbusDataContent = (
          <InfoTable
            data={data.sbus_data.cloudroom.CoMModel.CloudRoom}
            rowInfos={[
              { Name: 'Data Source ID', Value: 'dataSourceID' },
              { Name: 'Environment', Value: 'environment' },
              { Name: 'ID', Value: 'ID' },
              { Name: 'Life Cycle Status', Value: 'lifeCycleStatus' },
            ]}
          />
        );
      } else {
        sbusDataContent = <strong>Data in the SBUS channel is not valid</strong>;
      }
    } else {
      sbusDataContent = <strong>No data has been forwarded to SBUS</strong>;
    }
  }

  // CLI config
  const cliConfigEnabled = (!!data.federated_user_roles && !data.classic_login_opt_out) || !!data.idc_user_roles;

  // Organizations and SCPs
  const orgOuId = data.org_ou_id;
  const orgUnits = data.org_parents ?? [];
  const sortedOrgUnits = sortParents(orgOuId, orgUnits);
  const ouTree = renderTreeView(sortedOrgUnits, data);

  const scpEnabled = !(data.fpc_status === 'deleted');

  const temporarilyUnlockRootOrder = function (event) {
    createPortalOrder({
      action: 'temporarily-unlock-root-user',
      description: 'Temporarily Unlock Root User for AWS Account "' + data.account_id + '"',
      account_id: data.account_id,
      hours: event.srcElement.querySelector('input[name="hours"]').value.toString(),
    });
  };
  const temporarilyUnlockRootHoursChange = function (event) {
    let e = event.srcElement;
    if (e.value && (e.value < e.min || e.value > e.max)) {
      e.value = e.min;
    }
  };

  // temporary access
  const tempAccessEnabled = data.fpc_status !== 'deleted' && tempAccessRoles?.length;

  // shared - login details
  const append_switch_role_link = role => (
    <li>
      {role}:{' '}
      <a
        className={isAccountQuarantined ? 'disabled-link' : ''}
        href={createSwitchRoleLink(role, data.account_id, data.account_friendly_name, data.account_stage)}
        target="_blank"
        rel="noopener noreferrer">
        Switch Role
      </a>
    </li>
  );

  const append_switch_idc_role_link = role => (
    <li>
      {role}:{' '}
      <a
        className={isAccountQuarantined ? 'disabled-link' : ''}
        href={createSwitchIdcRoleLink(role, data.account_id, ssoStartUrls[data.master_account])}
        target="_blank"
        rel="noopener noreferrer">
        Switch Role
      </a>
    </li>
  );

  const login_details = (isAccountQuarantined = false) =>
    (data.federated_user_roles || data.idc_user_roles) && (
      <div>
        <h4>Login Details</h4>
        <p>
          You have access to:{' '}
          {!data.classic_login_opt_out && (
            <strong>{Number(data.federated_user_roles?.length) || '0'} IAM role(s), </strong>
          )}
          <strong>{Number(data.cdh_user_roles?.length) || '0'} CDH IAM role(s), </strong>
          <strong class="feature-flag-idc-enabled d-none">
            {Number(data.idc_user_roles?.length) || '0'} IDC role(s){' '}
          </strong>
          in this AWS account.
        </p>
        {!isAccountQuarantined && data.federated_user_roles?.length && !data.classic_login_opt_out && (
          <div>
            <p>
              Please{' '}
              <a href={CONF.awsLoginDetails.webeamLoginUrl} target="_blank" rel="noopener noreferrer">
                login to AWS with your Active Directory credentials
              </a>{' '}
              in order to access the following roles:
            </p>
            <ul>{data.federated_user_roles.map(append_switch_role_link)}</ul>
          </div>
        )}
        {!isAccountQuarantined && data.cdh_user_roles?.length && (
          <div>
            <p>
              Please{' '}
              <a href={CONF.awsLoginDetails.cdhLoginUrl} target="_blank" rel="noopener noreferrer">
                login to the CDH Portal
              </a>{' '}
              in order to access the following roles:
            </p>
            <ul>
              {data.cdh_user_roles.map(role => (
                <li>{role}</li>
              ))}
            </ul>
          </div>
        )}
        {!isAccountQuarantined && data.idc_user_roles?.length && (
          <div class="feature-flag-idc-enabled d-none">
            <p>
              Please{' '}
              <a
                href={ssoStartUrls[data.master_account]}
                target="_blank"
                rel="noopener noreferrer"
                className={isAccountQuarantined ? 'disabled-link' : ''}>
                login to AWS SSO Portal
              </a>{' '}
              to access identity center roles in the account.
            </p>
            <ul>{data.idc_user_roles.map(append_switch_idc_role_link)}</ul>
            {data.classic_login_opt_out && (
              <div>
                <p>
                  Account is opted out from Classic Login (IAM Center) and is compliant with the Privileged Access
                  Management (PAM) governance rules.
                </p>
                <p>PAM Compliance Status:</p>
                <p>
                  IAM integration <i class="fa fa-check portal-success" aria-hidden="true"></i>
                </p>
                <p>
                  The <code>bmwawscli</code> is not applicable for login to this account. Please use the native{' '}
                  <code>aws</code> CLI.
                </p>
                <p>
                  Additional info:{' '}
                  <a
                    href="https://docs.aws.amazon.com/cli/latest/userguide/getting-started-install.html"
                    target="_blank"
                    className={isAccountQuarantined ? 'disabled-link' : ''}>
                    {' '}
                    AWS Documentation
                  </a>
                </p>
              </div>
            )}
            <p>
              Please order access to this account via{' '}
              <a
                href="https://portal.rightnow.azure.cloud.bmw/"
                target="_blank"
                rel="noopener noreferrer"
                className={isAccountQuarantined ? 'disabled-link' : ''}>
                RightNow
              </a>{' '}
              and search for <code>{data.account_id}</code> in the category:{' '}
              <code>Base Technology & Infrastructure -&gt; AWS FPC -&gt; FPC Standard Cloudroom Roles</code>
            </p>
            <p>
              The Right Now documentation is available here:{' '}
              <a
                href="http://rightnow-info.bmwgroup.net"
                target="_blank"
                rel="noopener noreferrer"
                className={isAccountQuarantined ? 'disabled-link' : ''}>
                http://rightnow-info.bmwgroup.net
              </a>
            </p>
          </div>
        )}
        {!data.classic_login_opt_out && (
          <div class="feature-flag-idc-enabled d-none">
            <p>Account is using Classic IAM Center Login which is not compliant to PAM governance rules.</p>
            <p>PAM Compliance Status:</p>
            <p>
              IAM integration <i class="fa fa-times portal-danger" aria-hidden="true"></i>
            </p>
          </div>
        )}
      </div>
    );

  // sso_start_url maps to the AWS SSO start URL
  const ssoStartUrls = {
    740783461026: 'https://bmw-sso-int.awsapps.com/start',
    946848987374: 'https://bmw-sso.awsapps.com/start',
    216268842436: 'https://bmw-sso-int-secure.awsapps.com/start',
    708820737385: 'https://bmw-sso-secure.awsapps.com/start',
  };

  // support for feature flag
  let rowInfos = [
    { Name: 'Account Id', Value: 'account_id' },
    { Name: 'Root Account', Value: 'account_root_email' },
    { Name: 'Friendly Name', Value: 'account_friendly_name' },
    { Name: 'Description', Value: 'description' },
    { Name: 'FPC Status', Value: 'fpc_status' },
    { Name: 'Account Area', Value: 'account_area' },
    { Name: 'Account Type', Value: 'account_type' },
    { Name: 'Account Stage', Value: 'cost_data.cloudroom_stage' },
    { Name: 'AWS Support Plan', Value: 'support_plan' },
    { Name: 'IT Responsible', Value: 'it_responsible' },
    { Name: 'Primary Responsible', Value: 'primary_responsible' },
    { Name: 'Secondary Responsible', Value: 'sec_responsible' },
    { Name: 'Creation Date', Value: 'creation_date' },
    { Name: 'Organizations Master Account', Value: 'master_account' },
    { Name: 'Organizations Id', Value: 'org_id' },
    { Name: 'Organizations Name', Value: 'org_name' },
    { Name: 'Organizations Unit Id', Value: 'org_ou_id' },
    { Name: 'Organizations Unit Name', Value: 'org_ou_name' },
    { Name: 'Organizations Account Status', Value: 'org_status' },
    { Name: 'Last Org Update (UTC)', Value: 'org_status_update' },
    { Name: 'App Criticality', Value: 'cost_data.app_criticality' },
    { Name: 'ITSM Service', Value: 'cost_data.itsm_service' },
  ];

  // TODO: this is the only thing that can't controlled by async feature flag
  if (featureFlagIdc?.properties?.enabled) {
    rowInfos.push({ Name: 'Classic Login Opt-Out Status', Value: 'classic_login_opt_out' });
  }

  return (
    <div class="detailsContainer">
      <ul class="nav nav-tabs">
        <li class="nav-item active">
          <a
            class="nav-link active"
            data-bs-target={'#account' + data.account_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="true"
            aria-expanded="true">
            Account
          </a>
        </li>
        <li class="nav-item">
          <a
            class={
              'nav-link' +
              (alternateContactsEnabled ? '' : ' disabled') +
              (isAccountQuarantined && alternateContactsEnabled ? ' disabled' : '')
            }
            data-bs-target={'#alternate_contacts' + data.account_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Alternate Contacts
          </a>
        </li>
        <li class="nav-item">
          <a
            class={'nav-link' + (isAccountQuarantined ? ' disabled' : '')}
            data-bs-target={'#cost_data' + data.account_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            Cost Data
          </a>
        </li>
        <li class="nav-item">
          <a
            class={
              'nav-link' +
              (cliConfigEnabled ? '' : ' disabled') +
              (isAccountQuarantined && cliConfigEnabled ? ' disabled' : '')
            }
            data-bs-target={'#cli' + data.account_id}
            role="tab"
            data-bs-toggle="tab"
            aria-selected="false"
            aria-expanded="false">
            CLI Config
          </a>
        </li>
        {hasAccess(data.account_id, ['view_all_accounts', 'manage_accounts']) && (
          <li class="nav-item">
            <a
              class={'nav-link' + (isAccountQuarantined ? ' disabled' : '')}
              data-bs-target={'#org' + data.account_id}
              role="tab"
              data-bs-toggle="tab"
              aria-selected="false"
              aria-expanded="false">
              Organizations and SCPs
            </a>
          </li>
        )}
        {tempAccessEnabled && (
          <li class="nav-item">
            <a
              class={'nav-link'}
              data-bs-target={'#access' + data.account_id}
              role="tab"
              data-bs-toggle="tab"
              aria-selected="false"
              aria-expanded="false">
              Temp. Access
            </a>
          </li>
        )}
      </ul>
      <div class="tab-content detailsTab">
        <div class="tab-pane fade active show" id={'account' + data.account_id} role="tabpanel">
          <div class="row" name="account-details-container">
            <div class="col-lg-12 col-xl-12" name="warnings-and-orders">
              {data.marked_for_deletion && data.fpc_status !== 'deleted' && (
                <div class="detailsContent">
                  <div style="color:black;">
                    <h3>
                      <strong>Warning:</strong> AWS Account is marked for deletion!
                    </h3>
                  </div>
                </div>
              )}

              {data.orders && Object.getOwnPropertyNames(data.orders).length > 0 && (
                <div class="detailsContent">
                  <table class="table table-hover dataTable row-border nowrap compressed">
                    <thead>
                      <th class="compressed">Order Id</th>
                      <th class="compressed">Action</th>
                      <th class="compressed">Proposer</th>
                      <th class="compressed">Creation Date</th>
                    </thead>
                    <tbody>{order_rows}</tbody>
                  </table>
                </div>
              )}
            </div>
            <div class="col-lg-12 col-xl-8" name="account-details-left-side">
              <div class="detailsContent">
                <h4>AWS Account Details</h4>
                {isAccountQuarantined && (
                  <div class="d-flex justify-content-between align-items-center mb-3">
                    <h5 class="alert alert-danger px-3 py-2 mb-0">
                      Info: Account is quarantined, no actions are possible.
                    </h5>
                    {!deletedAccount && hasManageQuarantinePermission() && (
                      <button
                        type="button"
                        class="btn btn-danger temporary-privilege-escalation"
                        onclick={e => {
                          unquarantineAccount(data.account_id);
                        }}
                        id="unquarantine-account-button"
                        style="height: 100%;">
                        <i class="fas fa-circle-exclamation"></i> Reactivate Account
                      </button>
                    )}
                  </div>
                )}
                <div>
                  <InfoTable data={data} rowInfos={rowInfos} quarantined={isAccountQuarantined} />
                </div>
              </div>
              <div class="detailsContent">
                <div class="btn-group detail-actions-btn-group">
                  {hasAdminAccountAccess(data) && (
                    <>
                      <button
                        type="button"
                        class="btn-sm space"
                        id="trigger-account-update-button"
                        onclick={() => accountUpdateHandler('trigger-account-update-button', data.account_id)}
                        title="Collect account information like the alternate contacts or the support level">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        <i class="fas fa-sync-alt" /> Trigger Account Update
                      </button>
                      <button
                        disabled={isAccountDetailsPage && isAccountQuarantined}
                        type="button"
                        class="btn-sm space"
                        onclick={editAccountFct}>
                        {isAccountDetailsPage ? (
                          <>
                            <i class="fas fa-pencil-alt" /> Edit Account
                          </>
                        ) : (
                          <>
                            <i class="fas fa-external-link-alt" /> Account Details
                          </>
                        )}
                      </button>
                      {!deletedAccount &&
                        !isAccountQuarantined &&
                        !isAdministrationAccount &&
                        hasManageQuarantinePermission() &&
                        data.account_id && (
                          <button
                            type="button"
                            class="space btn btn-small btn-danger temporary-privilege-escalation d-none"
                            id="quarantine-account-button"
                            onclick={() => showKillSwitchModal(data.account_id)}>
                            <i class="fas fa-circle-exclamation" /> Kill Switch
                          </button>
                        )}

                      <button
                        type="button"
                        disabled={isAccountQuarantined}
                        class="btn-sm space feature-flag-region-enable-regions d-none"
                        onclick={enableRegion}>
                        <i class="fas fa-external-link-alt" /> Enable Region
                      </button>
                      <button
                        type="button"
                        disabled={isAccountQuarantined}
                        class="btn-sm space feature-flag-region-disable-regions d-none"
                        onclick={disableRegion}>
                        <i class="fas fa-external-link-alt" /> Disable Region
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-xl-4" name="account-details-right-side">
              <div
                class={'detailsContent' + (isAccountQuarantined ? ' text-muted' : '')}
                name="account-details-switch-role-links">
                {login_details(isAccountQuarantined)}
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id={'alternate_contacts' + data.account_id} role="tabpanel">
          <div class="row" name="alternate-contacts-container">
            <div class="col-lg-12 col-xl-8">
              <div class="detailsContent table-fixed">
                <h4>Alternate Contacts</h4>
                <div name="alternate-contacts-billing">
                  {data.alternate_contacts?.BILLING && (
                    <div class="mt-4">
                      <h3 class="overview">BILLING</h3>
                      <div>
                        <InfoTable
                          data={data.alternate_contacts?.BILLING}
                          rowInfos={[
                            { Name: 'Contact', Value: 'Title' },
                            { Name: 'Phone Number', Value: 'PhoneNumber' },
                            { Name: 'Email', Value: 'EmailAddress' },
                            { Name: 'Actions', Value: 'actions' },
                          ]}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div name="alternate-contacts-operations">
                  {data.alternate_contacts?.OPERATIONS && (
                    <div class="mt-4">
                      <h3 class="overview">OPERATIONS</h3>
                      <div>
                        <InfoTable
                          data={data.alternate_contacts.OPERATIONS}
                          rowInfos={[
                            { Name: 'Contact', Value: 'Title' },
                            { Name: 'Phone Number', Value: 'PhoneNumber' },
                            { Name: 'Email', Value: 'EmailAddress' },
                            { Name: 'Actions', Value: 'actions' },
                          ]}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div name="alternate-contacts-security">
                  {data.alternate_contacts?.SECURITY && (
                    <div class="mt-4">
                      <h3 class="overview">SECURITY</h3>
                      <div>
                        <InfoTable
                          data={data.alternate_contacts.SECURITY}
                          rowInfos={[
                            { Name: 'Contact', Value: 'Title' },
                            { Name: 'Phone Number', Value: 'PhoneNumber' },
                            { Name: 'Email', Value: 'EmailAddress' },
                            { Name: 'Actions', Value: 'actions' },
                          ]}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id={'cost_data' + data.account_id} role="tabpanel">
          <div class="row" name="cost-data-container">
            <div class="col-lg-12 col-xl-6">
              <div class="detailsContent">
                <h4>Cost Data Details</h4>
                {costDataContent}
              </div>
              <div class="detailsContent">
                <div id="infobox" class="card infobox">
                  <div class="card-body">
                    Visit the billing DB to <b>view</b> or <b>update</b> the cost data.
                    <br />
                    Data can be updated only from <b>IT responsible</b>, <b>primary responsible</b> and{' '}
                    <b>secondary responsible</b>.
                  </div>
                </div>
                <div class="btn-group detail-actions-btn-group">
                  <a
                    class="btn-sm space"
                    target="_blank"
                    style="text-decoration: none"
                    href={'https://cloudbilling.bmwgroup.net/user/mycosts?roomid=' + data.account_id}>
                    <i
                      class="fas fa-external-link-alt"
                      style="margin-right: 0.5em; font-size: smaller; align-self: center; margin-top: -0.1em"
                    />
                    View Costs{' '}
                  </a>
                  <a
                    class="btn-sm space"
                    target="_blank"
                    style="text-decoration: none"
                    href={'https://cloudbilling.bmwgroup.net/user/billingdata?roomid=' + data.account_id}>
                    <i
                      class="fas fa-external-link-alt"
                      style="margin-right: 0.5em; font-size: smaller; align-self: center; margin-top: -0.1em"
                    />
                    Update Cost Data{' '}
                  </a>
                  <a
                    class="btn-sm space"
                    target="_blank"
                    style="text-decoration: none"
                    href={'https://cloudbilling.bmwgroup.net/user/appdata?roomid=' + data.account_id}>
                    <i
                      class="fas fa-external-link-alt"
                      style="margin-right: 0.5em; font-size: smaller; align-self: center; margin-top: -0.1em"
                    />
                    Update Application Data{' '}
                  </a>
                </div>
              </div>
            </div>
            {sbusDataContent && (
              <div class="col-lg-12 col-xl-6">
                <div class="detailsContent">
                  <h4>SBUS Related Data</h4>
                  {sbusDataContent}
                </div>
              </div>
            )}
          </div>
        </div>

        <div class="tab-pane fade" id={'cli' + data.account_id} role="tabpanel">
          <div class="row" name="cli-config-container">
            <div class="col-lg-12 col-xl-4">
              <div class="detailsContent" name="cli-config-switch-role-links">
                {login_details(isAccountQuarantined)}
              </div>
            </div>

            <div class="col-lg-12 col-xl-8">
              <div class="detailsContent" name="cli-config-code">
                {!data.classic_login_opt_out && (
                  <div class="feature-flag-idc-enabled d-none">
                    <h4>AWS CLI Config</h4>
                    <p>
                      Here you can find a configuration example for your AWS command line based on this specific AWS
                      account and your available roles. By default your AWS CLI config file is located in your home
                      directory (Windows: <code>%userprofile%\.aws\config</code>, Linux and Mac:{' '}
                      <code>~/.aws/config</code>).
                    </p>
                    <pre class="add-line-numbers">
                      <span># Configuration for all of your target AWS accounts and IAM roles.</span>
                      <span># Here you can find additional information on how to configure this file:</span>
                      <span>
                        #{' '}
                        <a
                          href="https://docs.aws.amazon.com/cli/latest/topic/config-vars.html"
                          target="_blank"
                          rel="noopener noreferrer">
                          https://docs.aws.amazon.com/cli/latest/topic/config-vars.html
                        </a>
                      </span>
                      {cliConfigEnabled &&
                        data.federated_user_roles &&
                        data.federated_user_roles.map(role => (
                          <>
                            <span></span>
                            <span>
                              [profile {data.account_id}_{role.split('/').pop()}]
                            </span>
                            <span>output=json</span>
                            <span>region={CONF.awsLoginDetails.loginRegion}</span>
                            <span>
                              role_arn=arn:{CONF.awsLoginDetails.awsPartition}:iam::{data.account_id}:role/{role}
                            </span>
                            <span>source_profile={CONF.awsLoginDetails.awsCliProfile}</span>
                          </>
                        ))}
                    </pre>
                    <p></p>
                    <p>
                      The command <code>bmwaws login</code> (respectively <code>bmwaws login --cn</code> for AWS China)
                      of the python-based BMWAWS CLI requests AWS credentials via WebEAM.Next login and saves them to
                      the file <code>credentials</code> in the same directory.
                    </p>
                    <pre class="add-line-numbers">
                      <span># The command line tool can be downloaded from its repository:</span>
                      <span>
                        #{' '}
                        <a
                          href="https://atc-github.azure.cloud.bmw/FPCBMW/aws-fpc-cli"
                          target="_blank"
                          rel="noopener noreferrer">
                          BMWAWS CLI in ATC Github
                        </a>
                      </span>
                      <span></span>
                      <span>[bmwsso]</span>
                      <span>region = eu-west-1</span>
                      <span>aws_access_key_id = ACCESS_KEY</span>
                      <span>aws_secret_access_key = SECRET_ACCESS_KEY</span>
                      <span>aws_session_token = SESSION_TOKEN</span>
                      <span>valid_until = {new Date().toISOString().split('T')[0]} 23:43:54+00:00</span>
                    </pre>
                    <br />
                  </div>
                )}
                {data.idc_user_roles && (
                  <div class="feature-flag-idc-enabled d-none">
                    <h4>AWS SSO Config</h4>
                    <p>
                      Here you can find a configuration example for your AWS command line based on this specific AWS
                      account and your available roles. By default your AWS CLI config file is located in your home
                      directory (Windows: <code>%userprofile%\.aws\config</code>, Linux and Mac:{' '}
                      <code>~/.aws/config</code>).
                    </p>
                    <pre class="add-line-numbers">
                      <span>[sso-session default]</span>
                      <span>sso_start_url = {ssoStartUrls[data.master_account]}</span>
                      <span>sso_region = eu-central-1</span>
                      <span>sso_registration_scopes = sso:account:access</span>
                    </pre>
                    <p></p>
                    <p>
                      The command <code>aws configure sso</code> can create the above configuration for you. The region
                      needs to be <code>eu-central-1</code>, as AWS Identity Center is in this single region available.
                      You need to authenticate during the signin via a browser window.
                    </p>
                    <p>
                      Once the authentication is done, the authentication allows you to select the account and
                      permission sets you have access to. For each permission set a profile is automatically created
                      once it was selected. The profile is created like this
                    </p>
                    <pre class="add-line-numbers">
                      {cliConfigEnabled &&
                        data.idc_user_roles &&
                        data.idc_user_roles.map(role => (
                          <>
                            <span>
                              [profile {role}-{data.account_id}]{' '}
                            </span>
                            <span>sso_session = default</span>
                            <span>sso_account_id = {data.account_id}</span>
                            <span>sso_role_name = {role}</span>
                            <span>region = {CONF.awsLoginDetails.loginRegion}</span>
                          </>
                        ))}
                    </pre>
                    <p></p>
                    <p>
                      In order to re-signing on AWS you need to run <code>aws sso login --sso-session default</code>.
                      Afterwords all profiles stored in <code>~/.aws/config</code> based on the default sso session are
                      usable.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* The content of the `Organizations and SCPs` tab */}
        <div class="tab-pane fade" id={'org' + data.account_id} role="tabpanel">
          <div class="row" name="org-details-container">
            {/* Content on the upper left corner, a table with some Org related information */}
            <div class="col-lg-12 col-xl-6">
              <div class="detailsContent">
                <h4>AWS Organizations</h4>
                <p>Find some information about the AWS account within the Organization</p>
                <div>
                  <InfoTable
                    data={data}
                    rowInfos={[
                      { Name: 'Organizations Master Account', Value: 'master_account' },
                      { Name: 'Organizations Id', Value: 'org_id' },
                      { Name: 'Organizations Name', Value: 'org_name' },
                      { Name: 'Organizations Unit Id', Value: 'org_ou_id' },
                      { Name: 'Organizations Unit Name', Value: 'org_ou_name' },
                      { Name: 'Organizations Account Status', Value: 'org_status' },
                      { Name: 'Last Org Update (UTC)', Value: 'org_status_update' },
                    ]}
                  />
                </div>
              </div>
            </div>

            {/* Content on the upper right corner, the OU structure which shows where the account is located */}
            <div class="col-lg-12 col-xl-6" name="org-container">
              <div class="detailsContent">
                <h4>OU Structure</h4>
                <p>
                  Here you can see where your AWS account is placed in the current OU structure. Visit the{' '}
                  <a
                    href="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/1_beforeyoustart/platformdoc/overview_organization/organization/"
                    target="_blank"
                    rel="noopener noreferrer">
                    Developer Portal
                  </a>
                  <i class="px-1 fas fa-external-link-alt" /> for more details.
                </p>
                <p>
                  <pre>{ouTree}</pre>
                </p>
                <p>
                  Last Update of the OU info for this account: <strong>{data.org_status_update} UTC</strong>.
                </p>
              </div>
            </div>

            {/* Content at the bottom of the tab, buttons to load/disable/enable SCPs and accordions to show the SCPs */}
            {scpEnabled && (
              <div class="col-lg-12 col-xl-12" name="scp-container">
                <div class="col">
                  <div class="detailsContent">
                    <h4>
                      Service Control Policies <small>(SCPS)</small>
                    </h4>
                    <p>
                      Please click the button "Load SCPs" to load the currently assigned Service Control Policies for
                      your AWS account.
                    </p>
                    <div class="btn-group detail-actions-btn-group">
                      <button
                        type="button"
                        class="btn btn-sm"
                        id="load-scp-button"
                        onclick={() => listScpsHandler('load-scp-button', data.account_id, sortedOrgUnits)}
                        title="Load all SCPs that are currently associated with this AWS account">
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        <span class="fas fa-search" aria-hidden="true"></span> Load SCPs
                      </button>
                      {checkValueAgainstList(
                        'manage_accounts',
                        localStorage.permissions ? localStorage.permissions : false,
                      ) && (
                        <>
                          <button
                            type="button"
                            class="btn btn-sm"
                            onclick={event => temporarilyUnlockRootOrder(event)}
                            title="Temporary unlock the AWS root user for support tasks">
                            <span class="fas fa-unlock" aria-hidden="true">
                              {' '}
                            </span>{' '}
                            Temporarily Unlock Root for{' '}
                            <input
                              name="hours"
                              type="number"
                              size="2"
                              min="1"
                              max="12"
                              value="1"
                              onclick={event => event.stopPropagation()}
                              onkeyup={event => temporarilyUnlockRootHoursChange(event)}
                            />{' '}
                            hour(s)
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div class="col" id={'account_details_scp_' + data.account_id}></div>
              </div>
            )}
          </div>
        </div>

        <div class="tab-pane fade" id={'access' + data.account_id} role="tabpanel">
          <div class="col-lg-12 col-xl-12" name="temp-access-container">
            <div class="detailsContent">
              <h4>Temporary Account Access</h4>
              <p>
                Here you can request temporary access for <strong>maximum 1h</strong> for AWS account{' '}
                <strong>{data.account_id}</strong>. You'll get a link which opens a new browser tab with the target AWS
                session. You can't be logged in at AWS in the same browser session twice, so please log out from any
                other AWS session in your browser before you can use this link. All temporary access requests are logged
                in order to have a auditability.
              </p>
              <div name={'role_details_' + data.account_id}>
                <table class="table table-hover dataTable row-border compressed">
                  <thead>
                    <th class="table table-hover dataTable row-border nowrap compressed">Role</th>
                    <th class="table table-hover dataTable row-border nowrap compressed">Action</th>
                    <th class="table table-hover dataTable row-border nowrap compressed">Link</th>
                  </thead>
                  <tbody>
                    {tempAccessRoles.map(role => (
                      <tr role="row" class="compressed">
                        <td class="name small-width">
                          {role}
                          {copyButtonJSXHelper()}
                        </td>
                        <td class="value small-width">
                          <button
                            class="btn-sm space text-nowrap"
                            onclick={event => showTempRoleModal(event.target, role)}>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="fas fa-user"></span> Get Temporary Access
                          </button>
                        </td>
                        <td class="value temp-link-column">
                          <span id={'role_link_value_' + role.split('/').pop()}>***</span>
                          {copyURLJSXHelper()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
